.content { border-style: solid; border-color: $white; border-width: 0 $pageBorder; overflow: hidden; }

/* Block */

.block { padding: 4em 0; }
.block.block-narrow { padding: 1.5em 0; }

.block-alt, .bgBlack { background: $bgBlack; color: $white; }
.block-alt a:not(.btn), .bgBlack a:not(.btn) { color: $white; }
.block-alt-2, { background: $brown; color: $white; }

.block.block-border { border-bottom: 1px solid $borderGray; }

.block.block-bottom-pattern { background: $bgBlack url('../img/pattern-3.png') bottom 2px left repeat-x; background-size: auto 115px; }
.block.block-top-pattern { background: $bgBlack url('../img/pattern-4.png') top 2px left repeat-x; background-size: auto 154px; }

.block.block-half { background: linear-gradient(to right, $white 50%, $bgBlack 50%); overflow: hidden; }

.block-half-img { position: relative; padding-top: 3rem; }
.block-half-img .block-img { width: 50%; height: 100%; position: absolute; left: 50%; top: 0; overflow: hidden; }
.block-half-img .block-img img { width: 100%; height: auto; }

.block.block-alpha { padding-top: 0; }
.block.block-omega { padding-bottom: 0; }
.block.block-articles { padding-bottom: 2em; }
.block.block-specializations { background: $black; }

.block-bg { position: relative; }
.block-bg-image { @include bgImageWrapper(); position: absolute; width: 100%; height: 100%; left: 0; top: 0; }
.block-bg-image img { max-width: 100%; }

.block.block-narrow { padding: 2.75rem 0 3em 0; }

.box { background: $lightBrown; font-weight: 500; padding: 2em 2.5rem; }
.box-head { font-size: 1rem; text-transform: uppercase; margin-bottom: 0.5rem; }
.box a { color: $black; }

.column { display: flex; flex-flow: column; height: 100%; }

.section-content { margin-bottom: 3rem; }

.sections-head { display: flex; flex-wrap: wrap; color: $gray; font-size: 2.4rem; line-height: 1.25; font-weight: 600; margin-bottom: 1.75rem; }
.sections-head h1, .sections-head .h1 { color: $black; margin-bottom: 0; }
.sections-head a { color: $brown; white-space: nowrap; }
.sections-head .icon { width: 0.75em; height: 0.75em; margin: 0.25em 0.2em 0 0; }
.sections-head-slash { display: inline-block; margin: 0 0.4em 0 0.4em; }

/* Teaser */

.teaser { padding: 0; background: $bgBlack; color: $white; overflow: hidden; position: relative; }
.teaser-bg { position: relative; left: 0; top: 0; width: 100%; height: 100%; }
.teaser-content { position: absolute; bottom: 10vw; width: 100%; }
.teaser-content.teaser-content-relative { position: relative; bottom: auto; margin: 3.5rem 0; }
.teaser-head { color: $white; position: relative; }
.teaser-text { font-size: 0.8rem; margin-top: 2.7rem; }

/* Homepage */

.reasons { position: relative; margin: 0 0.75rem 4rem 0; color: $white; padding: 2rem; border: 2px solid $brown; border-radius: 5px; min-height: 10rem; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; }
.reason-number { position: absolute; background: $bgBlack; font-size: 2.4rem; padding: 0.75rem; line-height: 1; font-weight: 500; }
.reason-number:hover { cursor: pointer; }

.reason:nth-child(1) .reason-number { left: 20%; top: 0; transform: translate(-50%, -50%); }
.reason:nth-child(2) .reason-number { left: 40%; top: 0; transform: translate(-50%, -50%); }
.reason:nth-child(3) .reason-number { left: 60%; top: 0; transform: translate(-50%, -50%); }
.reason:nth-child(4) .reason-number { left: 80%; top: 0; transform: translate(-50%, -50%); }

.reason:nth-child(6) .reason-number { left: 80%; bottom: 0; transform: translate(-50%, 50%); }
.reason:nth-child(7) .reason-number { left: 60%; bottom: 0; transform: translate(-50%, 50%); }
.reason:nth-child(8) .reason-number { left: 40%; bottom: 0; transform: translate(-50%, 50%); }
.reason:nth-child(9) .reason-number { left: 20%; bottom: 0; transform: translate(-50%, 50%); }

.reason:nth-child(5) .reason-number { top: 50%; left: 100%; transform: translate(-50%, -50%); }
.reason:nth-child(10) .reason-number { top: 50%; left: 0; transform: translate(-50%, -50%); }

.reason-text { opacity: 0; transform: translateY(-1rem); transition: all 0.15s ease-out; font-size: 1.3rem; font-weight: 600; text-align: center; }
.reason-text.active { opacity: 1; transform: translateY(0); transition: all 0.3s ease-in; }
.reason-default .reason-text, .reason-default .reason-text.active { transform: none; transition: all 0.15s ease-in; }
.reason-text p { margin-bottom: 0.15rem; }

.block-citation { min-height: 42vw; display: flex; align-items: center; }
.block-citation-image:after { @include darkBottomGradient; }
.citation-text { font-style: italic; font-size: 1.3rem; line-height: 1.55; }
.citation-author { font-weight: 500; font-size: 0.8rem; color: $brown; text-transform: uppercase; position: relative; margin-bottom: 0; }
.citation-author:before { content: ""; display: inline-block; width: 0.9rem; height: 2px; background: $brown; margin: 0.33em 0.5em; }

.block-circles { padding-top: calc(5.75rem); }
.block-circles:before { content: ""; display: block; width: 100%; height: 36px; background: url('../img/border-pattern.png') 0 0 repeat-x; background-size: auto 100%; position: absolute; top: 0; left: 0; opacity: 0.5; z-index: 10; }
.block-circles-image { height: 100%; width: 100%; padding-bottom: 0.25rem; left: auto; right: 5rem; text-align: right; }
.block-circles-image img { height: 100%; width: auto; display: inline-block; }

/* Who we are */

.persons-block { margin-top: 11rem;  }
.persons { margin-left: -2rem; margin-bottom: -2rem; margin-top: -6.25rem; align-items: flex-end; }
.persons .item { width: 33.33333%; padding-left: 2rem; margin-bottom: 2rem; display: block; text-decoration: none; position: relative; }
.persons .item:nth-child(3n+1):after { content: ""; display: block; pointer-events: none; width: 300vw; height: 115px; opacity: 0.5; background: url('../img/pattern-3.png') 0 0 repeat-x; background-size: auto 100%; position: absolute; left: -100vw; top: 6.25rem; z-index: 1; }
.persons .item-inner { position: relative; z-index: 10; background: $bgBlack; }
.persons .item-img { margin-bottom: 0.5rem; }
.persons .item-title { font-size: 1.2rem; font-weight: 500; margin-bottom: 0.1em; text-decoration: underline; }
.persons .item:hover, .persons .item:active { .item-title { text-decoration: none; } }
.persons .item-subtitle { color: $brown; font-size: 0.8rem; font-weight: 500; text-transform: uppercase; }
.persons .item-content p:last-child { margin-bottom: 0; }

.milestones { display: flex; flex-wrap: wrap; align-items: flex-start; position: relative; padding-top: 3.5rem; margin-bottom: -1rem; }
.milestone { width: calc(50% - 1.75rem); background: $white; color: $black; position: relative; }
.milestone:after { content: ""; display: block; width: 5px; background: #82807f; position: absolute; top: 2.5rem; z-index: 1; }
.milestone:nth-child(odd) { margin-right: 1.75rem; margin-bottom: 5.25rem; margin-top: -3.5rem; }
.milestone:nth-child(odd):after { right: -1.75rem; transform: translateX(50%); height: calc(100% + 1.5rem); }
.milestone:nth-child(even) { margin-left: 1.75rem; margin-bottom: 1.75rem; }
.milestone:nth-child(even):after { left: -1.75rem; transform: translateX(-50%); height: calc(100% - 1.5rem); }
.milestone:last-child:after { display: none; }
.milestone:nth-last-child(2):nth-child(odd):after { height: 3.5rem; }

.milestone-head { display: flex; align-items: center; flex: 1 1 0; position: relative; padding: 1.5rem; height: 5rem; }
.milestone-head:before { content: ""; display: block; width: 1.75rem; height: 2px; background: $white; position: absolute; top: 2.5rem; }
.milestone-head:after { content: ""; display: block; width: 10px; height: 10px; background: $white; position: absolute; top: 2.5rem; border-radius: 50%; z-index: 5; }
.milestone:nth-child(odd) .milestone-head:before { right: -1.75rem; }
.milestone:nth-child(odd) .milestone-head:after { right: -1.75rem; transform: translate(5px, -5px); }
.milestone:nth-child(even) .milestone-head:before { left: -1.75rem; }
.milestone:nth-child(even) .milestone-head:after { left: -1.75rem; transform: translate(-5px, -5px); }
.milestone-head.sh-trig:hover { cursor: pointer; }
.milestone-year { width: 6.5rem; color: $brown; font-size: 2.4rem; color: $brown; font-weight: 500; line-height: 1; margin-right: 1rem; flex: 0 0 6.5rem; }
.milestone-title { margin-bottom: 0; font-size: 1rem; font-weight: 500; position: relative; flex: 1 1 0; padding-right: 2.5rem; }
.sh-trig .milestone-title:after { content: ""; display: block; width: 1.5rem; height: 1.5rem; background: url('../svg/src/icon_plus-brown.svg') center center no-repeat; background-size: 100% 100%; position: absolute; right: 0; top: 50%; transform: translateY(-50%); }
.sh-trig.active .milestone-title:after { background-image: url('../svg/src/icon_minus-brown.svg'); }
.milestone-content { width: 100%; padding: 0 3rem 1.5rem 9rem; }

/* Person detail */

.person-detail .teaser { position: relative; border-bottom: 2px solid $borderBrown; min-height: 11rem; }
.person-detail .teaser:after { @include darkBottomGradient; z-index: 2; }
.person-detail .teaser-content { bottom: 2.5rem; z-index: 10; }
.person-detail .teaser-head { margin-bottom: 0.3rem; }
.person-detail .teaser-text { font-size: 0.8rem; text-transform: uppercase; font-weight: 500; color: #a1a1a1; margin-top: 0; }

.teaser-controls-wrapper { text-align: right; }
.teaser-controls { display: inline-flex; align-items: stretch; border: 2px solid $borderBrown; width: auto; }
.teaser-control { display: block; text-decoration: none; color: $lighterBrown; background: $bgBlack; padding: 1rem 0.75rem; }
.teaser-control { &:hover, &:active { color: $white; } }
.teaser-control .icon { width: 2rem; height: 2rem; }
.teaser-control-list { border-left: 2px solid $borderBrown; border-right: 2px solid $borderBrown; padding: 0.7rem 0.75rem; }
.teaser-control-list .icon { width: 3rem; height: 2.7rem; }

.person-detail-content { color: $lighterBrown; }
.person-detail-content a:not(.btn) { color: $lighterBrown; }
.person-detail-content a:not(.btn) { &:hover, &:active { color: $white; } }

.person-detail-info { margin-bottom: 1.25rem; line-height: 1.75; }
.person-detail-info h2, .person-detail-info .h2 { font-size: 0.8rem; color: $white; text-transform: uppercase; margin-bottom: 0.3rem; }
.person-detail-info p { margin-bottom: 0; }

.person-detail-text { font-size: 1rem; line-height: 1.6; }

/* Blog */
.articles { align-items: stretch; }
.articles .item { width: 33.3333%; margin-bottom: 2rem; text-align: left; text-decoration: none; }
.articles .item-inner { background: $white; color: $black; padding: 1.5rem 1.75rem; position: relative; height: 100%; }
.articles .item-img { margin-bottom: 1.2rem; position: relative; }
.articles .item-title { margin-bottom: 1rem; }
.articles .item-title h3 { font-weight: 700; }
.articles .item-info { margin-bottom: 1rem; display: flex; justify-content: space-between; font-size: .8rem; color: $brown; }
.articles .item-text { font-size: 0.8rem; }
.articles .item-tags { position: absolute; top: 1rem; right: 1rem; max-width: 40%; }
.articles .item-tag { padding: .2rem .5rem; background: $brown; color: $white; font-size: .7rem; margin-bottom: .3rem; text-align: center; }

.articles a.item:hover .item-title { color: $brown; }

.article-detail .head-info { color: $brown; margin-bottom: 1rem; }
.article-detail .perex { text-align: center; }
.article-detail .separator { padding: 0 1rem; }
.article-detail .head-image { margin-bottom: 1.5rem; }
.article-detail .prevNextButtons { display: flex; justify-content: space-evenly; margin-top: 2rem; }
.article-detail a { display: flex; justify-content: space-between; align-items: center; min-width: 200px; }

/* What we do */

.specializations { margin-left: -2rem; margin-bottom: -1.5rem; align-items: stretch; }
.specializations .item { width: 33.33333%; padding-left: 2rem; margin-bottom: 2rem; }
.specializations .item-inner { background: $white; color: $black; padding: 1.5rem 1.75rem; position: relative; height: 100%; }
.specializations .item-title { font-size: 1rem; font-weight: 500; text-decoration: underline; padding-right: 2.2rem; margin-bottom: 0.5rem; }
.specializations .item-inner { &:hover .item-title, &:active .item-title { text-decoration: none; color: $brown; } }
.specializations .item-text { margin-bottom: 0; font-size: 0.8rem; }
.specializations .item-icon { position: absolute; top: 1.25rem; right: 1.5rem; width: 1.7rem; height: 1.7rem; }

/* Specialization detail */

.specialization-detail { padding: 1.5rem 3rem 3rem 3rem; }
.specialization-detail .box { margin-top: 3.5rem; }

.panel { position: relative; width: 100%; }
.panel p { margin-bottom: 0.6rem; }
.panel-head { font-size: 1rem; margin-bottom: 0.75rem; font-weight: 500; text-transform: uppercase; }
.panel-head a { color: inherit; }

.panel-left { background: $black; color: $brown; left: 0; margin: 0 15px 0 -15px; height: 100%; padding: 3rem 2.75rem; }

.panel-right { right: 0; margin: 0 -15px 0 15px; border-left: 2px solid $lightBrown; padding: 3rem 2.75rem 1rem 4rem; }
.panel-right a { color: $black; }

.panel-list { margin: 0 -2.75rem 1rem 0; }
.panel-list li { font-size: 0.8rem; margin: 0; position: relative; padding: 0 2.75rem 0 0; }
.panel-list li a { color: $brown; display: block; line-height: 1.7; padding: 0.5rem 0; border-top: 2px solid $borderBrown; }
.panel-list li:first-child a { border-top: none; padding-top: 0.4rem; }
.panel-list li a:hover, .panel-list li a:active { color: $white; text-decoration: none; }
.panel-list li.active a { color: $white; text-decoration: none; }
.panel-list li.active:after { content: ""; display: block; width: 0; height: 0; border-style: solid; border-width: 0.7rem 0.7rem 0.7rem 0; border-color: transparent $white transparent transparent; position: absolute; right: 0; top: 50%; transform: translateY(-0.75rem); }

.panel-persons .item { display: block; color: $black; margin-bottom: 1.25rem; }
.panel-persons .item-img { margin-bottom: 0.5rem; }
.panel-persons .item-title { font-size: 0.8rem; font-weight: 500; margin-bottom: 0; }
.panel-persons .item { &:hover .item-title, &:active .item-title { color: $brown; } }

.block-specializations .articles { margin-left: -3.8rem; }
.block-specializations .articles .item { width: 40%; }
.blog-specialization-header { margin: 2rem 0; padding-left: 3rem; }
.blog-specialization-header-bottom { display: none; }
.specialization-articles-button-wrapper { padding-bottom: 5rem; margin-left: -3.8rem; position: relative; }
.specialization-articles-button-wrapper a { position: absolute; left: 34%; min-width: 203px; text-align: center; }

/* Value */

.value { font-size: 2.4rem; line-height: 1.2; font-weight: 500; color: $brown; display: inline-block; padding: 1.15rem 1.75rem; border: 5px solid $brown; margin-bottom: 1.75rem; }

.values { margin-left: -2rem; margin-bottom: -1.5rem; align-items: stretch; }
.values .item { width: 33.33333%; padding-left: 2rem; margin-bottom: 2rem; }
.values .item-inner { background: $white; color: $black; padding: 1.7rem 1.5rem 1.75rem 4.5rem; position: relative; height: 100%; }
.values .item-number { font-size: 2.4rem; color: $brown; position: absolute; left: 1.6rem; top: 1.7rem; font-weight: 500; line-height: 1; }
.values .item-title { font-size: 1rem; font-weight: 500; padding-right: 2.2rem; margin-bottom: 0.5rem; }
.values .item-text { margin-bottom: 0; font-size: 0.8rem; }

/* Contact */

.block-contact { position: relative; color: $lighterBrown; }
.block-contact .contact-text .contact-name { font-size: 1rem; text-transform: none; margin-bottom: 0.2rem; }
.block-contact .contact-text p { font-size: 1rem; line-height: 1.7; }
.block-contact .contact-text a { text-transform: none; color: $lighterBrown; }

.maps { height: 100%; width: 50%; position: absolute; left: 50%; top: 0; }
.maps .tab-content { width: 100%; height: 100%; }
.map-wrap, .map { height: 100%; width: 100%; }

/* Paginator */
.paginator .pager { display: flex; flex-direction: row; justify-content: center; }
.paginator ul { display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; }
.paginator ul li { margin-bottom: .75em; }
.paginator ul a span { padding: 0 .5rem; }
.paginator ul li a { height: 60px; width: 60px; font-size: 0.8rem; display: flex; justify-content: center; align-items: center; border: 2px solid $brown; color: $brown; font-weight: 500;  text-decoration: none; text-transform: uppercase; }
.paginator .btn { height: 60px;  display: flex; justify-content: center; align-items: center; }
.paginator .btn.next { margin-left: 1em; }
.paginator ul li.counter { display: none; }
.paginator ul li a.active,
.paginator ul li a:hover { background: $brown; color: $white; }
.block-alt .paginator a { color: $brown; }

@media screen and (max-width: 1600px) {
	.persons-block { margin-top: 9.5rem;  }
	.persons { margin-top: -5rem; }
	.persons .item:nth-child(3n+1):after { transform: scale(0.9); top: 4.8rem; }

	.teaser-control { padding: 1rem 0.75rem; }
	.teaser-control-list { border-left: 2px solid $borderBrown; border-right: 2px solid $borderBrown; padding: 0.7rem 0.75rem; }
	.teaser-control .icon { width: 1.5rem; height: 1.5rem; }
	.teaser-control-list .icon { width: 2.5rem; height: 2.2rem; }
	.specialization-articles-button-wrapper a { position: absolute; left: 32%; }
}

@media screen and (max-width: 1400px) {
	.persons .item:nth-child(3n+1):after { transform: scale(0.8); top: 4.4rem; }
}

@media screen and (max-width: $xl) {
	.citation-text { font-size: 1.1rem; }

	.block-circles-image { right: 1rem; }

	.sections-head { font-size: 2rem; }

	.teaser-control .icon { width: 1.3rem; height: 1.3rem; }
	.teaser-control-list .icon { width: 2.3rem; height: 2rem; }

	.specialization-detail { padding: 1.5rem 1.5rem; }
	.panel-right { padding-left: 3rem; }

	.value { font-size: 2.1rem; }

	.articles .item { width: 50%; }
}

@media screen and (max-width: $lg) {
	.persons .item:nth-child(3n+1):after { display: none; }

	.sections-head { font-size: 1.8rem; }

	.teaser-control { padding-bottom: 0.6rem; }

	.specializations .item { width: 50%; }
	.values .item { width: 50%; }

	.specialization-detail { padding: 1.5rem 0; }
	.specialization-detail .box { margin-top: 1.5rem; padding: 1.5em 1.75rem; }
	.panel-left { padding: 2rem 1.75rem; margin: 0 0 1.5rem 0; }
	.panel-right { padding: 2rem 0; border-left: none; margin: 0; }
	.blog-specialization-header { display: none; }
	.blog-specialization-header-bottom { display: block; }
	.block-specializations .articles { margin-left: -1.5em; display: flex; justify-content: space-evenly; }
	.block-specializations .articles .item { width: 45%; }
	.specialization-articles-button-wrapper { padding-bottom: 2rem; margin-left: 0; display: flex; justify-content: center; }
	.specialization-articles-button-wrapper a { position: relative; left: initial; }

	.paginator ul a span { display: none; }
	.paginator .btn { width: 60px; }
	.paginator .btn.prev { margin-left: 1em; }
}

@media screen and (max-width: $md) {
	.content { border-width: 0 $xsPageBorder; }

	.block.block-half { background: $white; padding: 0; }

	.block-half-light { padding-top: 3.5rem; padding-bottom: 3.5rem; }

	.block-half-dark { position: relative; padding-top: 3.5rem; padding-bottom: 3.5rem; }
	.block-half-dark > * { z-index: 10; position: relative; }
	.block-half-dark:after { content: ""; display: block; width: 300vw; height: 100%; background: $bgBlack; position: absolute; left: -100vw; top: 0; z-index: 1; }

	.teaser-content { bottom: 6vw; }

	.block-circles-image { display: none; }

	.persons .item { width: 50%; }

	.sections-head { font-size: 1.6rem; }

	.block-half-img { padding-bottom: 0; padding-top: 1rem; }
	.block-half-img .block-img { width: 100%; position: relative; left: 0; top: 0; margin-top: 1.5rem; }

	.milestones { padding-top: 0; }
	.milestone { width: calc(100% - 1.5rem); }
	.milestone:after { height: calc(100% + 1.5rem) !important; }
	.milestone:nth-child(odd) { margin-right: 0; margin-left: 1.5rem; margin-bottom: 1.5rem; margin-top: 0; }
	.milestone:nth-child(odd):after { right: auto; left: -1.5rem; transform: translateX(-50%); }
	.milestone:nth-child(odd) .milestone-head:before { right: auto; left: -1.5rem; }
	.milestone:nth-child(odd) .milestone-head:after { right: auto; left: -1.5rem; transform: translate(-5px, -5px); }
	.milestone-head { height: auto; max-height: none; }

	.milestone-year { font-size: 2.2rem; }

	.value { font-size: 1.9rem; border-width: 4px; padding: 1rem 1.5rem}

	.block-contact { padding-bottom: 0; padding-top: 2.5rem; }
	.maps { width: 100%; position: relative; height: 500px; left: 0; margin-top: 3rem; }

	.paginator ul li { padding-left: .75em; }
}

@media screen and (max-width: $sm) {
	.citation-text { font-size: 1rem; }

	.milestone-year { font-size: 2rem; }

	.teaser-controls { transform: scale(0.9); }
	.teaser-controls-wrapper { text-align: left; }

	.teaser-bg { height: 460px; }
	.teaser:after { @include darkBottomGradient; z-index: 2; }
	.teaser-bg img { max-width: none; width: auto; height: 100%; position: absolute; left: 50%; top: 0; transform: translateX(-50%); }
	.teaser-content { z-index: 10; }
	.teaser-text { margin-top: 1.5rem; }

	.teaser-content.teaser-content-relative { margin-top: 0; }

	.person-detail .teaser:after { height: 10rem; }
	.person-detail .teaser-text { color: $white; }

	.specializations .item { width: 100%; }
	.values .item { width: 100%; }

	.articles .item { width: 100%; }
	.block-specializations .articles .item { width: 80%; }
}

@media screen and (max-width: $xs) {
	.content { border-width: 0 $xsPageBorder; }

	.teaser-bg { height: 360px; }

	.teaser-head { font-size: 1.6rem; }
	.teaser-content { bottom: 4vw; }
	.teaser-text { font-size: 0.7rem; }

	.reason-number { font-size: 1.6rem; }
	.reason-text { font-size: 1.1rem; }

	.block-citation { min-height: 48vw; padding-bottom: 1rem; flex-wrap: wrap; padding-top: 0; }
	.block-bg-image.block-citation-image { position: relative; }
	.citation-text { font-size: 1rem; }

	.persons { justify-content: center; }
	.persons .item { width: 100%; }

	.milestone-year { font-size: 1.3rem; width: 3rem; flex-basis: 3rem; }
	.milestone-head { padding: 1rem; }
	.milestone-title { padding-right: 1.75rem; }
	.sh-trig .milestone-title:after { transform: scale(0.8) translateY(-50%); }
	.milestone-content { width: 100%; padding: 0.5rem 1.5rem 1.5rem 1.5rem; }

	.person-detail .teaser-bg { height: 320px; }
	.person-detail .teaser-bg img { max-width: none; width: auto; height: 100%; position: relative; left: 0; transform: none; }

	.article-detail a { min-width: 150px; }
}

@media screen and (max-width: $xxs) {
	.teaser-head { font-size: 1.2rem; }

	.value { font-size: 1.7rem; border-width: 4px; padding: 0.8rem 1.25rem}
}
.footer { padding: 3.5rem $pageBorder; }

.footer-sep { border-left: 1px solid $borderGray; padding-left: 5.5rem; }

.footer-nav li { font-size: 0.8rem; text-transform: uppercase; margin-bottom: 1rem; position: relative; }
.footer-nav li a { color: $black; }

.services-nav li { padding-left: 0.8em; }
.services-nav li:before { content: ""; display: block; width: 3px; height: 3px; background: $brown; position: absolute; left: 0; top: 0.6em; border-radius: 50%; }

.contacts-nav-wrapper { overflow: hidden; }
.contacts-nav { display: flex; flex-wrap: wrap; overflow: hidden; margin-left: -1rem; }
.contacts-nav li { padding-left: 1rem; }
.contacts-nav li:before { content: ""; display: block; width: 6px; height: 1px; background: $brown; position: absolute; left: 0.35rem; top: 0.7em; }

.contact { display: flex; }
.contact-img { width: 5rem; height: 5rem; flex: 0 0 5rem; overflow: hidden; border-radius: 50%; margin-right: 1.5rem; }
.contact-text { font-size: 0.8rem; text-transform: uppercase; }
.contact-text p { margin-bottom: 0; }
.contact-text a { color: $black; }
.contact-text .contact-name { font-weight: 500; margin-bottom: 1rem; }

.porta { width: 69px; height: 15px; display: block; float: right; color: $gray; fill: $gray; position: absolute; right: $pageBorder; bottom: 3rem; }
.porta:hover { color: $brown; fill: $brown; }

@media screen and (max-width: $md) {
	.footer { padding: 3.5rem $mdPageBorder; }

	.footer-sep { border-left: none; border-top: 1px solid $borderGray; padding-left: 0; padding-top: 3.5rem; margin-top: 3.5rem; }
}

@media screen and (max-width: $xs) {
	.footer { padding: 3.5rem $xsPageBorder; }
}
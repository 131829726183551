.header { height: 5.5rem; padding: 0 $pageBorder; background: $white; }
.header .logo { display: block; position: absolute; left: 0; top: 1.5em; width: 14.5rem; z-index: 19000; }

.header-bottom { border-bottom: 1px solid $borderGray;  }

/* Main navigation */

.main-nav { margin: 0; }
.main-nav ul { margin: 0; text-align: center; }
.main-nav ul li { padding: 0; margin: 0; background: none; display: inline-block; }
.main-nav ul li a { color: $blackBrown; font-size: 0.8em; text-transform: uppercase; display: block; line-height: 5.55rem; white-space: no-wrap; padding: 0 0.6em; text-decoration: none; transition: color 0.1s ease-in; }
.main-nav ul li a:hover, .main-nav ul li a:active { color: $brown; text-decoration: underline; }
.main-nav ul li.active a { color: $brown; }

.menu-trigger { border: none; width: 2.75rem; height: 2.75rem; position: absolute; right: 2.5rem; top: 1.35rem; display: none; text-align: center; padding: 0.4em; z-index: 1100; }
.menu-trigger .icon-menu { fill: $brown; width: 2rem; height: 2rem; display: block; }

.lang-switch { position: absolute; right: 0; top: 1.75rem; text-align: center; z-index: 2000; }
.lang-switch a { display: block; text-decoration: none; font-size: 0.8rem; text-transform: uppercase; font-weight: 500; }
.lang-switch-trigger { width: 2rem; height: 2rem; background: $brown; color: $white; border-radius: 50%; padding: 0.55em 0; }
.lang-switch-trigger:hover, .lang-switch-trigger:active { color: $white; }
.langs { max-height: 0; transition: max-height 0.2s ease-in; overflow: hidden; background: $bgBlack; position: absolute; left: 50%; top: 2.4rem; transform: translateX(-50%); }
.langs.langs-open { max-height: 500px; }
.langs li { margin: 0; padding: 0; }
.langs li .lang { display: block; color: $white; padding: 0.15rem 0.75rem; }
.langs li:first-child .lang { padding-top: 0.4rem; }
.langs li:last-child .lang { padding-bottom: 0.4rem; }
.langs li .lang:hover { color: $brown; }
.langs li.langs-sep { border-bottom: 2px solid #454443; padding-bottom: 0.1rem; margin-bottom: 0.1rem; }

@media screen and (max-width: $lg) {
	.main-nav { margin-left: 7rem; }
}

@media screen and (max-width: $md) {
	.header-bottom { display: none; }

	.header { padding: 0 $mdPageBorder; }
	.header .logo { width: 12rem; }
	.menu-trigger { display: block; }

	.header { height: auto; min-height: 5em; width: 100%; border-bottom: none; }
	.header-logo { position: relative; left: 0; top: 1.5em; }
	.header-logo img { width: 11.25em; height: 2.5em; }
	
	.main-nav { background: $bgBlack; z-index: 1050; width: 100%; min-height: 100vh; left: 100%; margin: 0; transform: translateX($mdPageBorder); top: 5.5rem; padding: 3em 1em 1em 1em; position: absolute; transition: left 0.25s ease-in; }
	.main-nav ul { width: 100%; }
	.main-nav ul li { display: block; width: 100%; padding: 0; }
	.main-nav ul li a { line-height: 3.3; font-size: 0.9rem; text-decoration: none; color: $white; }
	.main-nav ul li a { display: block; }
	.main-nav ul li a:after { display: none; }

	.menu-open { overflow: hidden; height: 100vh; }
	.menu-open .main-nav { left: 0; transform: translateX(0); }

	.langs li .lang { padding: 0.3rem 0.75rem; }
}

@media screen and (max-width: $xs) {
	.header { padding: 0 $xsPageBorder; }
}

@media screen and (max-width: 340px) {
	.header .logo { width: 10.5rem; top: 1.75rem; }
	.menu-trigger { right: 2.25rem; }
}
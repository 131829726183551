.btn { font-size: 0.8rem; display: inline-block; vertical-align: middle; border: 2px solid $brown; background: transparent url('../svg/src/icon_arrow-brown.svg') 0.5rem center no-repeat; background-size: auto 0.7rem; color: $brown; font-weight: 500; padding: 1em 1.5em 0.9em 3.85em; text-decoration: none; text-transform: uppercase; }
.btn:hover, .btn:active { color: $white; background: $brown; background-image: url('../svg/src/icon_arrow-white.svg'); background-size: auto 0.7rem; background-position: 0.5rem center; background-repeat: no-repeat; }
.bgBlack .btn, .block-alt .btn { &:hover, &:active { color: $black; background-image: url('../svg/src/icon_arrow-black.svg'); background-size: auto 0.7rem; background-position: 0.5rem center; background-repeat: no-repeat; } }

.btn-anchor { background-image: url('../svg/src/icon_arrow-brown-down.svg'); background-size: 0.75rem auto; padding-left: 2.3rem; background-position: 0.85rem center; }
.btn-anchor:hover, .btn-anchor:active { background-image: url('../svg/src/icon_arrow-white-down.svg'); background-size: 0.75rem auto; background-position: 0.85rem center; }

.btn.btn-alt { background: initial; padding-left: 1.5em; }
.btn.btn-alt:hover,
.btn.btn-alt:active,
.btn.btn-alt:focus, { background: $brown; color: $white; }

form.articleFilter .btn { background: initial; padding-left: 1.5em; }
form.articleFilter .btn:hover { background: $brown; color: $white; }

form.articleFilter .btn.btn-alt2 { background: $brown; color: $white; }
form.articleFilter .btn.btn-alt2:hover { background: initial; color: $brown; }

form.articleFilter button { visibility: hidden; }
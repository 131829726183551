.tabs { position: relative; }

.tab-nav ul { margin: 0 0 0 -1px; padding: 0; }
.tab-nav li { float: left; list-style-type: none; padding: 0; background: none; margin: 0 0 0 1px; }
.tab-nav .tab-switch { display: block; text-decoration: underline; outline: 0; color: $lighterBrown; text-transform: uppercase; padding: 0.5rem 1rem; }

.tab-content .tab { float: left; width: 100%; display: none; }
.tab-content .tab.selected { position: static !important; display: block; }

/* Map tabs*/

.tab-nav li { float: none; }
.tab-nav .tab-switch { font-size: 1rem; position: relative; padding: 0.75rem 1rem 0.75rem 1.75rem; border-top: 2px solid $borderBrown; line-height: 1.3; }
.tab-nav .tab-switch:before { content: ""; display: block; width: 0.75rem; height: 0.75rem; background: url('../svg/src/icon_plus-brown.svg'); background-size: 100% 100%; position: absolute; left: 0.5rem; top: 1rem; }
.tab-nav .tab-switch { &:hover, &:active { color: $white; text-decoration: none; &:before { background-image: url('../svg/src/icon_plus-white.svg'); } } }
.tab-nav .tab-switch-text { display: none; line-height: 1.8; padding-left: 1.75rem; font-size: 0.8rem; margin-bottom: 0.88rem; }
.tab-nav .tab-switch-text p { margin-bottom: 0; }
.tab-nav .tab-switch-text a { color: $lighterBrown; }
.tab-nav .tab-switch.selected { color: $white; }
.tab-nav .tab-switch.selected:before { background-image: url('../svg/src/icon_minus-white.svg'); }
.tab-nav .tab-switch.selected ~ .tab-switch-text { display: block; }
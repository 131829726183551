/* Select */

div.selector { font-size: 18px; background: #fff; color: #024b41; line-height: 45px; height: 42px; padding: 0; position: relative; overflow: hidden; cursor: pointer; text-align: left; }
div.selector span { text-overflow: ellipsis; display: block; overflow: hidden; white-space: nowrap; background: #fff url('../img/select.png') right 0 no-repeat; border: none; padding: 0; text-indent: 15px; height: 42px; line-height: 42px; cursor: pointer; display: block; }
div.selector select { text-align: left; opacity: 0; filter: alpha(opacity=0); -moz-opacity: 0; border: none; background: none; position: absolute; height: 42px; line-height: 42px; top: 0; left: 0; width: 100%; cursor: pointer; }
div.selector select option { padding: 2px 10px; }

div.selector.active span { background-position: right 0; }
div.selector.focus span { background-position: right 0; }
div.selector.hover span { background-position: right -52px; }
div.selector.hover.active span { background-position: right -52px; }
div.selector.focus.active span { background-position: right -52px; }

/* Checkbox */

div.checker { position: relative; }
div.checker, div.checker span, div.checker input { width: 20px; height: 18px; }
div.checker span { display: -moz-inline-box; display: inline-block; *display: inline; zoom: 1; text-align: center; background: url('../img/uf.png') -52px -11px no-repeat; }
div.checker span.checked { background-position: -52px -51px; }
div.checker input { margin: 0; cursor: pointer; opacity: 0; filter: alpha(opacity=0); -moz-opacity: 0; border: none; background: none; display: -moz-inline-box; display: inline-block; *display: inline; zoom: 1; vertical-align: top; }

div.checker.active span { background-position: -52px -51px; }
div.checker.active span.checked { background-position: -52px -11px; }
div.checker.hover span, div.checker.focus span { background-position: -52px -11px; }
div.checker.hover span.checked, div.checker.focus span.checked { background-position: -52px -51px; }
div.checker.hover.active span, div.checker.focus.active span { background-position: -52px -11px; }
div.checker.hover.active span.checked, div.checker.focus.active span.checked { background-position: -52px -51px; }

/* Radio */

div.radio { position: relative; }
div.radio, div.radio span, div.radio input { width: 24px; height: 26px; cursor: pointer; }
div.radio span { display: -moz-inline-box; display: inline-block; *display: inline; zoom: 1; text-align: center; background: url('../img/uf.png') -8px -8px no-repeat; }   
div.radio span.checked { background-position: -8px -48px; }
div.radio input { margin: 0; opacity: 0; filter: alpha(opacity=0); -moz-opacity: 0; margin: 0; border: none; background: none; display: -moz-inline-box; display: inline-block; *display: inline; zoom: 1; text-align: center; }
  
div.radio.active span { background-position: -8px -48px; }
div.radio.active span.checked { background-position: -8px -8px; }
div.radio.hover span, div.radio.focus span { background-position: -8px -48px; }
div.radio.hover span.checked, div.radio.focus span.checked { background-position: -8px -48px; }
div.radio.hover.active span, div.radio.focus.active span { background-position: -8px -48px; }
div.radio.hover.active span.checked, div.radio.focus.active span.checked { background-position: -8px -48px; }

/* Uploader */

div.uploader { background-position: 0 -297px; height: 28px; width: 190px; cursor: pointer; position: relative; overflow: hidden; }
div.uploader span.action { background-position: right -409px; height: 28px; line-height: 28px; width: 85px; text-align: center; float: left; display: inline; overflow: hidden; cursor: pointer; }
div.uploader span.filename { text-overflow: ellipsis; display: block; overflow: hidden; white-space: nowrap; float: left; cursor: default; height: 24px; margin: 2px 0 2px 2px; line-height: 24px; width: 82px; padding: 0 10px; }
div.uploader input { opacity: 0; filter: alpha(opacity=0); -moz-opacity: 0; border: none; background: none; position: absolute; top: 0; right: 0; float: right; cursor: default; width: 100%; height: 100%; }
div.uploader.active span.action { background-position: right -465px; }
div.uploader.hover, div.uploader.focus { background-position: 0 -353px; }
div.uploader.hover span.action, div.uploader.focus span.action { background-position: right -437px; }
div.uploader.hover.active span.action, div.uploader.focus.active span.action { background-position: right -493px; }
div.uploader.disabled, div.uploader.disabled.active { background-position: 0 -325px; }
div.uploader.disabled span.action, div.uploader.disabled.active span.action { background-position: right -381px; }
a { color: $brown; text-decoration: underline; }
a:hover, a:active { text-decoration: none; color: $brown; }

/* Heading */

h1, .h1 { font-weight: 600; font-size: 2.4rem; line-height: 1.25; margin: 0 0 0.5em 0; }
h2, .h2 { font-weight: 600; font-size: 1.3rem; line-height: 1.3; margin: 0 0 1em 0; }
h3, .h3 { font-weight: 500; font-size: 1rem; line-height: 1.3; margin: 0 0 0.75em 0; }
h4, .h4 { font-weight: 500; font-size: 0.8rem; line-height: 1.5; margin: 0 0 1em 0; }
h5, .h5 { font-weight: 300; font-size: 0.8rem; line-height: 1.5; margin: 0 0 1em 0; }
h6, .h6 { font-weight: 300; font-size: 0.8rem; line-height: 1.5; margin: 0 0 1em 0; }

.head-line { position: relative; margin-bottom: 2.3rem; }
.head-line:after { content: ""; display: block; width: 3rem; height: 4px; background: $brown; position: absolute; bottom: -1.1rem; left: 0; }
.head-line-center:after { left: 50%; transform: translateX(-50%); }

.section-head { margin-bottom: 1.5rem; }

.sup-head { color: $brown; font-size: 1.2rem; text-transform: uppercase; font-weight: 500; margin-bottom: 0.75em; }

/* Paragraphs */

p { margin: 0 0 1em 0; }
address { font-style: normal; }
cite { font-style: normal; }

/* Lists */

ul, ol { list-style: none;  margin: 0 0 1em 0; }
ul li { margin: 0 0 0.25em 0; padding: 0 0 0 1em; }
ul.unstyled li { padding: 0; background: none; }

ol { counter-reset: counter; margin-left: 0; *margin-left: 54px; }
ol li { margin: 0 0 0.5em 0; padding: 0 0 0 1em; *list-style-type: decimal; counter-increment: counter; position: relative; }
ol li:before { color: $gray; content: counter(counter)'. '; position: absolute; left: 0; top: 0; }

dl { margin: 0 0 1em 0; }
dt { font-weight: bold; }


.text-small { font-size: 0.7em; }
.text-big { font-size: 1.3em; }

.text-light { font-weight: 200; }
.text-bold { font-weight: 500; }
strong { font-weight: 500; }

.text-uppercase { text-transform: uppercase; }
.text-nowrap { white-space: nowrap; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.color-brown { color: $brown !important; }
.color-white { color: $white !important; }

.f-l { float: left; }
.f-r { float: right; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid $gray; margin: 1em 0; padding: 0; }

sup,sub { height: 0; line-height: 1; vertical-align: baseline; _vertical-align: bottom; position: relative; }
sup { bottom: 1ex; }
sub { top: .5ex; }

.entry { font-size: 0.8rem; line-height: 1.75; }
.entry h2 { margin-top: 1em; margin-bottom: 0.5em; }
.entry h3 { margin-top: 0.9em; }
.entry h2:first-child { margin-top: 0; }
.entry h3:first-child { margin-top: 0; }
.entry { p, ul, ol { margin-bottom: 1.25em; } }
.entry { p, ul, ol { &:last-child { margin-bottom: 0; } } }
.entry ul li { position: relative; padding-left: 1.75em; margin-bottom: 0.4em; }
.entry ul li:before { content: ''; position: absolute; left: 1em; top: 12px; width: 3px; height: 3px; border-radius: 50%; display: block; background: $brown; }

.perex, .entry.perex { font-size: 1rem; }

@media screen and (max-width: $xl) {
	h1, .h1 { font-size: 2rem; }
}

@media screen and (max-width: $lg) {
	h1, .h1 { font-size: 1.8rem; }
}

@media screen and (max-width: $md) {
	h1, .h1 { font-size: 1.6rem; }
	h2, .h2 { font-size: 1.2rem; }
}

@media screen and (max-width: $sm) {
	
}

@media screen and (max-width: $xs) {
	.head-line { margin-bottom: 1.8rem; }
	.head-line:after { width: 2.5rem; bottom: -0.9rem; }
}

@media screen and (max-width: $xxs) {
	h2, .h2 { font-size: 1.1rem; }
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,500,500i,600,600i&display=swap&subset=latin-ext');

$baseFont: 'Montserrat', sans-serif;
$baseFontSize: 20px;
$baseLineHeight: 1.5;
$baseFontWeight: 300;

$baseFontColor: #12100e;

$white: #fff;
$black: #12100e;
$bgBlack: #171514;
$brown: #c68c5e;
$lighterBrown: #b6876a;
$blackBrown: #342e1e;
$borderBrown: #362921;
$lightBrown: #e7e0db;
$gray: #b7b7b6;
$borderGray: #d6d5d2;

$pageBorder: 2rem;
$mdPageBorder: 1.5rem;
$xsPageBorder: 1rem;

$xl: 1370px;
$lg: 1200px;
$md: 992px;
$sm: 768px;
$xs: 576px;
$xxs: 420px;
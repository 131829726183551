form.std label { font: normal 16px/24px Arial,sans-serif; color: $black; margin: 0 0 10px 0; display: block; }
form.std input.text { font: normal 16px/24px Arial,sans-serif; color: $black; border: 1px solid $gray; padding: 8px 15px 6px 15px; width: 100%; }
form.std textarea { font: normal 16px/24px Arial,sans-serif; color: $black; resize: vertical; border: 1px solid $gray; padding: 8px 15px; width: 100%; min-width: 100%; max-width: 100%; height: 150px; min-height: 150px; max-height: 300px; }
form.std select { font: normal 16px/24px Arial,sans-serif; color: $black; width: 100%; padding: 2px 10px; }
form.std option { font: normal 16px/20px Arial,sans-serif; padding: 2px 20px; }

form.std input.text[required="required"] { background-image: url('../img/req.png'); background-position: right center; background-repeat: no-repeat; }

/*================================= Form standard =================================*/

/* Standard */

form.std .cell-set { margin: 0 0 0 -20px; }
form.std .cell { margin: 0 0 30px 0; padding-left: 20px; position: relative; text-align: left; }
form.std .cell-input { margin: 0 0 30px 0; padding-left: 20px; position: relative; text-align: left; }

/* Inline */

form.std .set-2 .cell { width: 50%; }
form.std .set-3 .cell { width: 33.3333%; }
form.std .set-4 .cell { width: 25%; }
form.std .set-5 .cell { width: 20%; }

form.std .toggle { position: relative; padding: 0 0 0 25px; }
form.std .toggle label { display: inline-block; *display: inline; zoom: 1; vertical-align: top; margin: 0 0 0 0; }
form.std .toggle .note { font-size: 16px; max-width: 100%; }

/* Various */

form.std .radio,
form.std .checker { position: absolute; left: 0; top: 0; }

/* Form contact */

.form-contact {  }

/* Article filter */
form.articleFilter input { visibility: hidden; position: absolute; }
form.articleFilter .tag-input:checked ~ .tag-label,
form.articleFilter .tag-input:not(:checked) ~ .tag-label:hover,
form.articleFilter .tag-input:not(:checked) ~ .tag-label:active { background: $brown; color: $white; }
form.articleFilter .category-wrapper { display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap; }
form.articleFilter .category-item { margin: .5em; }
